.img-wrapper {
  position: relative;
}

.img-responsive {
  width: 100%;
  height: auto;
  opacity: 75%;
}

.img-responsive:hover {
  opacity: 100%;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}


.img-pos{
  background-color: gray;
  border-radius: 50%;
}

.img-overlay:before {
  content: " ";
  display: block;
  /* adjust 'height' to position overlay content vertically */
}
